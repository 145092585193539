const RenderCountDown = (props) => {
  if (props.completed) return <></>;
  return (
    <div className="countdown">
      <div className="container">
        <h1 id="headline">Registration for {props.openingData.batchNo} Batch will open in</h1>
        <div id="countdown">
          <ul>
            <li>
              <span id="days">{props.days}</span>days
            </li>
            <li>
              <span id="hours">{props.hours}</span>Hours
            </li>
            <li>
              <span id="minutes">{props.minutes}</span>Minutes
            </li>
            <li>
              <span id="seconds">{props.seconds}</span>Seconds
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RenderCountDown;
