import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Odometer from "react-odometerjs";
import UserClock from "./UserClock";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  query,
  collection,
  onSnapshot,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDLig4Qy0AwFAQz_bNhUnqBvEZzSyhS_lQ",
//   authDomain: "de-suung-registration.firebaseapp.com",
//   projectId: "de-suung-registration",
//   // storageBucket: "de-suung-registration.appspot.com",
//   // messagingSenderId: "887306952643",
//   // appId: "1:887306952643:web:8eea19bcd4a95dc30f6776",
//   // measurementId: "G-D7T7BY46RT",
// };

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

const maleQuery = query(
  collection(db, "registrations"),
  where("gender", "==", "Male")
);
const femaleQuery = query(
  collection(db, "registrations"),
  where("gender", "==", "Female")
);

const RegistrationCount = () => {
  const [maleRegistrationCount, setMaleRegistrationCount] = useState(0);
  const [femaleRegistrationCount, setFemaleRegistrationCount] = useState(0);
  //   var socket = io("ws://localhost:3000/v1/", { transports: ["websocket"] });
  //   useEffect(() => {
  //     socket.on("connnection", () => {
  //       console.log("connected to server");
  //     });
  //     // alert("he");
  //   }, []);

  useEffect(() => {
    // const querySnapshot = await db.collection("registrations").get();
    const unsubscribe = onSnapshot(maleQuery, (snapshot) => {
      setMaleRegistrationCount(snapshot.size);
    });

    return () => unsubscribe;
  }, [maleRegistrationCount]);

  useEffect(() => {
    // const querySnapshot = await db.collection("registrations").get();

    const unsubscribe1 = onSnapshot(femaleQuery, (snapshot) => {
      setFemaleRegistrationCount(snapshot.size);
    });
    return () => unsubscribe1;
  }, [femaleRegistrationCount]);

  return (
    <Row className="registration-count" style={{ textAlign: "center" }}>
      <Col>
        TOTAL LIVE REGISTRATIONS
        <br />
        MALE:{" "}
        <span>
          <Odometer
            // animation="count"
            format="(dddd),dd"
            theme="train-station"
            duration={1000}
            value={maleRegistrationCount}
          />
        </span>{" "}
        FEMALE:{" "}
        <span>
          <Odometer
            // animation="count"
            auto={true}
            format="(dddd),dd"
            theme="train-station"
            duration={1000}
            value={femaleRegistrationCount}
          />
        </span>
      </Col>
      <hr
        style={{
          marginTop: "5px",
          marginBottom: "0px",
        }}
      />
      <UserClock />
    </Row>
  );
};

export default RegistrationCount;
