/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import * as yup from "yup";
import { PickupPoints as pickupPoint } from "../data/PickupPoints";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import ApplicationStatus from "../ApplicationStatus";
import RenderCountDown from "../RenderCountDown";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Countdown from "react-countdown";
import Badge from "react-bootstrap/Badge";

const BACKEND_URL = process.env.REACT_APP_URL;

function Home() {
  const [openingData, setOpeningData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [showEmployedForm, setShowEmployedForm] = useState(false);
  const [showQualificationForm, setShowQualificationForm] = useState(false);
  const dobMax = "2004-01-17";
  const maxDate = new Date(dobMax);
  const [minimumDateOfBirth, setMinimumDateOfBirth] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [applicationErrors, setApplicationErrors] = useState();
  const [applicationClosed, setApplicationClosed] = useState(false);
  const [applicationData, setApplicationData] = useState();
  const [openingDate, setOpeningDate] = useState();
  const [counterTime, setCounterTime] = useState();
  const [serverTime, setServerTime] = useState();
  const [showTimer, setShowTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [pickupPointList, setPickupPointList] = useState([]);
  const filterPickupPoints = (dzongkhagName) => {
    const aa = pickupPoint.find((item) => dzongkhagName === item.dzongkhag_name);
    if (aa !== undefined) {
      setPickupPointList(aa.pickupPoints);
    } else {
      setPickupPointList([]);
    }
  };

  const getApplicationOpening = async () => {
    setLoading(true);
    await axios
      .get(`${BACKEND_URL}/openings`)
      .then(function (response) {
        setOpeningData(response.data);
        if (response.data.status === "active") {
          setOpeningDate(response.data.openingDate);

          setServerTime(response.data.serverTime);
          setMinimumDateOfBirth(response.data.minimumDateOfBirth);
          setShowTimer(false);
          if (moment(response.data.openingDate).isBefore(moment(response.data.serverTime))) {
            setShowForm(true);
            setShowTimer(false);
          } else {
            const timeNow = moment();
            const serverT = moment(response.data.serverTime);
            const timeDifference = timeNow.diff(serverT);
            const oDate = moment(response.data.openingDate);
            const finalCounterTime = moment(oDate).add(timeDifference, "ms");
            setCounterTime(finalCounterTime.toISOString());
            // setShowForm(false);
          }
        } else {
          console.log("response", response);
          setApplicationClosed(true);
        }

        setLoading(false);
        setServerError(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoading(false);
        setServerError(true);
        setShowForm(false);
      });
  };

  const formSubmit = async (values, { setErrors, resetForm }) => {
    setIsSubmitting(true);
    await axios
      .post(`${BACKEND_URL}/applications`, values, {
        headers: {
          "x-api-key": "QZDldVWM7K8bDrJGaJiZh45EuNfDKZ56lwGv1zThVXACKojkaWLXJM75SYccsZSBK7h4KHZ6z28l4kl7WCS7qlz3nuN8IkZecHpoQXVeTQrrFk6ONgEtY7Z4aMgyzx9p",
        },
      })
      .then(function (response) {
        setApplicationData(response.data);
        setSubmitSuccess(true);
        resetForm();
        setShowForm(false);
        setSubmitError(false);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log("my error", error.response);
        // if (error.errors) {
        //   // Formik errors use camelcase for key values
        //   setErrors(error.errors);
        // }

        setSubmitSuccess(false);
        setSubmitError(true);
        setApplicationErrors(error.response.data);
        setIsSubmitting(false);
        setShowForm(true);
      });
  };

  useEffect(() => {
    getApplicationOpening();
  }, []);
  //"Wed January 17 2024 00:00:00 GMT+0600"
  const schema = yup.object().shape({
    name: yup.string().required().min(3),
    cid: yup.string().required().length(11),
    dob: yup
      .date()
      .required()
      .test("dob", "You are not eligible to apply due to your age", (date) => {
        return moment(minimumDateOfBirth).diff(moment(date), "years") >= 20;
      }),
    gender: yup.string().required(),
    email: yup.string().email().nullable(),
    maritalStatus: yup.string().nullable(),
    bloodGroup: yup.string().required(),
    phone: yup.string().required(),
    presentDzongkhag: yup.string().required(),
    pickupPoints: yup.string().required(),
    qualification: yup.string().required(),
    qualificationCourse: yup.string().when("qualification", {
      is: (value) => value === "Class XII Passed" || value === "Bachelors Degree" || value === "Diploma" || value === "Masters Degree" || value === "PhD",
      then: yup.string().required(),
    }),
    employmentStatus: yup.string().required(),
    employerDetails: yup.string(),
    employer: yup.string().when("employmentStatus", {
      is: (value) => value === "Employed",
      then: yup.string().required(),
    }),
    emergencyName: yup.string().required(),
    emergencyPhone: yup.string().required(),
    emergencyRelationship: yup.string().required(),
    uniformSize: yup.string().required(),
    shoeSize: yup.string().required(),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  return (
    <div
      className="App"
      style={{
        backgroundColor: "white",
        paddingBottom: "50px",
      }}
    >
      {loading ? (
        <div
          style={{
            // backgroundColor: "#ffd54f",
            paddingTop: "100px",
          }}
        >
          {/* <Loader type="Bars" color="#00BFFF" height={80} width={80} /> */}
          <Loader
            type="ThreeDots"
            color="#ffd54f"
            height={80}
            width={80}
            // timeout={3000} //3 secs
          />
        </div>
      ) : (
        <div style={{ animation: `fadeIn 1s` }}>
          {serverError && (
            <>
              <div
                style={{
                  // backgroundColor: "#ffd54f",
                  paddingTop: "100px",
                }}
              >
                {/* <Loader type="Bars" color="#00BFFF" height={80} width={80} /> */}
                <Loader
                  type="ThreeDots"
                  color="#ffd54f"
                  height={80}
                  width={80}
                  // timeout={3000} //3 secs
                />
                <br />
                <h4 id="headline">Loading</h4>
                <br />
                <p>This is taking longer than usual</p>
                <br />
                <Button variant="warning" size="lg" onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </div>
            </>
          )}
          {applicationClosed && (
            <Container>
              <div className="countdown">
                <div className="container">
                  <h1 id="headline">REGISTRATION CLOSED FOR {openingData.batchNo} BATCTH</h1>
                  <div>Registration for {openingData.batchNo} batch has been closed because we have reached the maximum number of registration for this batch. Thank you for your interest!</div>
                </div>
              </div>
            </Container>
          )}
          <Container>
            {counterTime && openingData && (
              <>
                <Countdown
                  renderer={(props) => <RenderCountDown openingData={openingData} {...props} />}
                  onComplete={() => {
                    window.location.reload();
                  }}
                  date={counterTime}
                ></Countdown>
              </>
            )}
            <hr />
            {openingData && openingData.status === "active" && (
              <Alert variant="primary">
                {openingData && openingData.announcementText && <>{openingData.announcementText}</>}
                <br />
              </Alert>
            )}
            <hr />
          </Container>

          <Row className="">
            <Col>
              <h5>
                Click{" "}
                <Badge bg="warning">
                  <a href="/live-registration-data" target="_blank">
                    here
                  </a>
                </Badge>{" "}
                to check the LIVE registration data
              </h5>
            </Col>
          </Row>
          {submitSuccess && <ApplicationStatus application={applicationData} />}
          {showForm && (
            <Container style={{ animated: `fadeIn 2s` }}>
              <Row className="banner" style={{ textAlign: "center" }}>
                <Col>Registration for Accelerated De-suung Integrated Training Program (61st Batch) is OPEN</Col>
              </Row>

              <Formik
                validationSchema={schema}
                onSubmit={formSubmit}
                initialValues={{
                  name: "",
                  cid: "",
                  dob: "",
                  gender: "",
                  email: "",
                  maritalStatus: "",
                  bloodGroup: "",
                  phone: "",
                  presentDzongkhag: "",
                  pickupPoints: "",
                  qualification: "",
                  employmentStatus: "",
                  employerDetails: "",
                  employer: "",
                  emergencyName: "",
                  emergencyPhone: "",
                  emergencyRelationship: "",
                  uniformSize: "",
                  shoeSize: "",
                  terms: false,
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched, values, touched, isValid, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-4">
                      <Card border="secondary">
                        <Card.Header
                          style={{
                            backgroundColor: "#dfe3e7",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          PERSONAL DETAILS
                        </Card.Header>
                        <Card.Body>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group
                                as={Col}
                                // controlId="validationFormik01"
                                className="position-relative"
                              >
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Your Full Name" name="name" value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>CID Number</Form.Label>
                                <Form.Control type="text" placeholder="Enter your CID Number" name="cid" value={values.cid} onChange={handleChange} isInvalid={!!errors.cid} autoComplete="off" />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.cid}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="date" name="dob" min={minimumDateOfBirth} onChange={handleChange} isInvalid={!!errors.dob} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.dob}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select placeholder="Select" name="gender" value={values.gender} onChange={handleChange} isInvalid={!!errors.gender}>
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.gender}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter Your Email" name="email" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.email}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Marital Status</Form.Label>
                                <Form.Select name="maritalStatus" placeholder="Select" value={values.maritalStatus} onChange={handleChange} isInvalid={!!errors.maritalStatus}>
                                  <option value="">Select</option>
                                  <option value="Single">Single</option>
                                  <option value="Married">Married</option>
                                  <option value="Divorced">Divorced</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.maritalStatus}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Blood Group</Form.Label>
                                <Form.Select name="bloodGroup" placeholder="Select" value={values.bloodGroup} onChange={handleChange} isInvalid={!!errors.bloodGroup}>
                                  <option value="">Select</option>
                                  <option value="A+">A+</option>
                                  <option value="B+">B+</option>
                                  <option value="O+">O+</option>
                                  <option value="AB+">AB+</option>
                                  <option value="A-">A-</option>
                                  <option value="B-">B-</option>
                                  <option value="O-">O-</option>
                                  <option value="AB-">AB-</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.bloodGroup}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" name="phone" value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.phone}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>

                    <Row className="mb-4">
                      <Card border="warning">
                        <Card.Header
                          style={{
                            backgroundColor: "#f5eaca",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          PRESENT DZONGKHAG / PICK-UP POINT
                        </Card.Header>
                        <Card.Body>
                          {/* <Card.Title>Success Card Title</Card.Title> */}
                          <Card.Text style={{ textAlign: "left" }}>
                            <Card.Title style={{ color: "red", fontWeight: "bold" }}>IMPORTANT: Please read carefully</Card.Title>
                            This information is collected for the purpose of allocation of Training Centre and transportation arrangement. In case you are selected, you will be picked up from the Dzongkhag &amp; Pick-up Point that you have chosen here. Please enter correct information. <br />
                            <span style={{ color: "red", fontWeight: "bold" }}>Note:Pick-up Point may be reallocated by the Motor Transport Officer (MTO) as appropriate</span>
                          </Card.Text>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Present Dzongkhag</Form.Label>
                                <Form.Select
                                  // placeholder="Select"
                                  name="presentDzongkhag"
                                  value={values.presentDzongkhag}
                                  // onChange={handleChange}
                                  isInvalid={!!errors.presentDzongkhag}
                                  onChange={(e) => {
                                    filterPickupPoints(e.target.value);
                                    setFieldValue("presentDzongkhag", e.target.value);
                                  }}
                                >
                                  <option value="">Select Dzongkhag</option>
                                  {pickupPoint.map((item, index) => (
                                    <option key={index} value={item.dzongkhag_name}>
                                      {item.dzongkhag_name}
                                    </option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.presentDzongkhag}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Pick-up Point</Form.Label>
                                <Form.Select
                                  // placeholder="Select"
                                  name="pickupPoints"
                                  value={values.pickupPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.pickupPoints}
                                >
                                  <option value="">Select</option>
                                  {pickupPointList.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.pickupPoints}
                                  </Form.Control.Feedback>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row className="mb-4">
                      <Card border="secondary">
                        <Card.Header
                          style={{
                            backgroundColor: "#dfe3e7",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          ACADEMIC DETAILS
                        </Card.Header>
                        <Card.Body>
                          {/* <Card.Title>Success Card Title</Card.Title> */}
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Qualification</Form.Label>
                                <Form.Select
                                  name="qualification"
                                  value={values.qualification}
                                  isInvalid={!!errors.qualification}
                                  onChange={(e) => {
                                    if (e.target.value === "Class X Passed" || e.target.value === "Class VIII Passed" || e.target.value === "Class VI Passed" || e.target.value === "No Education") {
                                      setFieldValue("qualificationCourse", "");
                                      setShowQualificationForm(false);
                                    } else setShowQualificationForm(true);
                                    setFieldValue("qualification", e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {/* <option value="No Education">
                                    No Education
                                  </option> */}
                                  <option value="No Education">No education</option>
                                  <option value="Class VI Passed">Class VI Passed</option>
                                  <option value="Class VIII Passed">Class VIII Passed</option>
                                  <option value="Class X Passed">Class X Passed</option>
                                  <option value="Class XII Passed">Class XII Passed</option>
                                  <option value="Diploma">Diploma</option>
                                  <option value="Bachelors Degree">Bachelors Degree</option>
                                  <option value="Masters Degree">Masters Degree</option>
                                  <option value="PhD">PhD</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.qualification}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {showQualificationForm && (
                              <Col md={4}>
                                <Form.Group as={Col} className="position-relative">
                                  <Form.Label>Degree/Course/Stream (if applicable)</Form.Label>
                                  <Form.Control type="text" name="qualificationCourse" value={values.qualificationCourse} onChange={handleChange} isInvalid={!!errors.qualificationCourse} />
                                </Form.Group>
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row className="mb-4">
                      <Card border="warning">
                        <Card.Header
                          style={{
                            backgroundColor: "#f5eaca",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          EMPLOYMENT DETAILS
                        </Card.Header>
                        <Card.Body>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Current Employment Status</Form.Label>
                                <Form.Select
                                  name="employmentStatus"
                                  value={values.employmentStatus}
                                  isInvalid={!!errors.employmentStatus}
                                  onChange={(e) => {
                                    if (e.target.value === "Employed") setShowEmployedForm(true);
                                    else setShowEmployedForm(false);
                                    setFieldValue("employmentStatus", e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="Employed">Employed</option>
                                  <option value="Unemployed">Unemployed</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.employmentStatus}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {showEmployedForm && (
                              <>
                                <Col md={4}>
                                  <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Employer</Form.Label>
                                    <Form.Select name="employer" value={values.employer} onChange={handleChange} isInvalid={!!errors.employer}>
                                      <option value="">Select</option>
                                      <option value="Private">Private</option>
                                      <option value="Government">Government</option>
                                      <option value="Corporate">Corporate</option>
                                      <option value="SOE">SOE</option>
                                      <option value="Self Employed">Self Employed</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                      {errors.employer}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Employment Details</Form.Label>
                                    <Form.Control type="text" placeholder="Name of the employing agency" name="employerDetails" value={values.employerDetails} onChange={handleChange} isInvalid={!!errors.employerDetails} />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                      {errors.employerDetails}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>

                    <Row className="mb-4">
                      <Card border="warning">
                        <Card.Header
                          style={{
                            backgroundColor: "#f5eaca",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          EMERGENCY CONTACT DETAILS
                        </Card.Header>
                        <Card.Body>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Emergency Contact Name</Form.Label>
                                <Form.Control type="text" name="emergencyName" value={values.emergencyName} onChange={handleChange} isInvalid={!!errors.emergencyName} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.emergencyName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" name="emergencyPhone" value={values.emergencyPhone} onChange={handleChange} isInvalid={!!errors.emergencyPhone} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.emergencyPhone}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Relationship to Applicant</Form.Label>
                                <Form.Control type="text" name="emergencyRelationship" value={values.emergencyRelationship} onChange={handleChange} isInvalid={!!errors.emergencyRelationship} />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.emergencyRelationship}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row className="mb-4">
                      <Card border="secondary">
                        <Card.Header
                          style={{
                            backgroundColor: "#dfe3e7",
                            fontWeight: "bold",
                            fontSize: "17px",
                          }}
                        >
                          UNIFORM / SHOE SIZE
                        </Card.Header>
                        <Card.Body>
                          <Row className="mb-3">
                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Uniform Size</Form.Label>
                                <Form.Select name="uniformSize" value={values.uniformSize} onChange={handleChange} isInvalid={!!errors.uniformSize}>
                                  <option value="">Select</option>
                                  <option value="S">S</option>
                                  <option value="M">M</option>
                                  <option value="L">L</option>
                                  <option value="XL">XL</option>
                                  <option value="XXL">XXL</option>
                                  <option value="Other">Other</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.uniformSize}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Shoe Size (UK)</Form.Label>
                                <Form.Select name="shoeSize" value={values.shoeSize} onChange={handleChange} isInvalid={!!errors.shoeSize}>
                                  <option value="">Select</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="Other">Other</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {errors.shoeSize}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>

                    <Row className="mb-4">
                      <Card border="warning">
                        <Card.Body>
                          <Row className="">
                            <Form.Group className="mb">
                              <Form.Check style={{ textAlign: "left" }} type="checkbox" label=" I agree that all the information provided herewith are true. My application may be rejected/cancelled if found void." name="terms" value={values.terms} onChange={handleChange} isInvalid={!!errors.terms} />
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.terms}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>

                    {submitError && (
                      <Row className="mb-4">
                        <Alert variant="danger">{applicationErrors?.message}</Alert>
                      </Row>
                    )}
                    <Row className="mb-4 text-center">
                      <Card>
                        <Card.Body>NOTE: If the submit button is not yellow, please check all the necessary fields are entered</Card.Body>
                      </Card>
                    </Row>

                    <Button variant={isValid ? "warning" : "light"} type="submit" size="lg" disabled={!isValid || isSubmitting}>
                      {isSubmitting ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          Submitting...
                        </>
                      ) : (
                        <>Submit</>
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
