import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "react-bootstrap/Alert";
import { FaCheckCircle } from "react-icons/fa";

const ApplicationStatus = (props) => {
  const { application } = props;
  const [background, setBackground] = useState("success");
  const [waitingList, setWaitingList] = useState("");

  const maleStandBy = 290;
  const maleIntake = 190;
  const femaleStandBy = 290;
  const femaleIntake = 190;

  useEffect(() => {
    if (application.gender === "Male" && application.rank > maleStandBy) {
      setBackground("danger");
      setWaitingList("Slots filled");
    } else if (application.gender === "Male" && application.rank > maleIntake) {
      setBackground("warning");
      setWaitingList("Waiting List");
    }
    if (application.gender === "Female" && application.rank > femaleStandBy) {
      setBackground("danger");
      setWaitingList("Slots filled");
    } else if (
      application.gender === "Female" &&
      application.rank > femaleIntake
    ) {
      setBackground("warning");
      setWaitingList("Waiting List");
    }

    // if (application.gender === "Male" && application.rank > 1240) {
    //   setBackground("warning");
    //   setWaitingList("Waiting List");
    // }
    // if (application.gender === "Female" && application.rank > 860) {
    //   setBackground("warning");
    //   setWaitingList("Waiting List");
    // }
  }, [application]);

  return (
    <Container>
      <Row className="mb-3">
        <FaCheckCircle size={50} style={{ fill: "green" }} />
        <h4 className="mt-4">REGISTRATION SUCCESSFUL</h4>
        <Alert variant={background} className="mt-4">
          <p>
            Registration successful for {application.name} (CID:{" "}
            {application.cid})
          </p>
          <p>
            {application.gender} registrant number: {application.rank}{" "}
            {waitingList ? `(${waitingList})` : ""}
          </p>
          {/* <p>Date/Time of Registration: {application.createdAt}</p> */}
          <hr />
          <p className="mb-4">
            We will announce the selection result soon. If you have not Applied
            for Security Clearance yet,
            <a
              target="_blank"
              href="http://scs.rbp.gov.bt/apply"
              rel="noreferrer"
            >
              <b>APPLY NOW !</b>
            </a>
          </p>
        </Alert>
        <div>
          <a href="/">
            <b>Back to Application form</b>
          </a>{" "}
          |{" "}
          <a target="_blank" href="/live-registration-data">
            <b>CHECK LIVE REGISTRATION</b>
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default ApplicationStatus;
