import { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar, Alert } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Button from "react-bootstrap/Button";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// import * as FirestoreService from "../services/firestore";

// import { initializeApp } from "firebase/app"

import { getFirestore, query, collection, onSnapshot, orderBy } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// }

// const firebaseConfig = {
//   apiKey: "AIzaSyDLig4Qy0AwFAQz_bNhUnqBvEZzSyhS_lQ",
//   authDomain: "de-suung-registration.firebaseapp.com",
//   projectId: "de-suung-registration",
//   // storageBucket: "de-suung-registration.appspot.com",
//   // messagingSenderId: "887306952643",
//   // appId: "1:887306952643:web:8eea19bcd4a95dc30f6776",
//   // measurementId: "G-D7T7BY46RT",
// };

const db = getFirestore();
const maleQuery = query(collection(db, "registrations"), orderBy("created_at", "asc"));

const BACKEND_URL = process.env.REACT_APP_URL;

function LiveRegistrations() {
  const [openingData, setOpeningData] = useState();

  const [maleRegistrationData, setMaleRegistrationData] = useState([]);
  const [femaleRegistrationData, setFemaleRegistrationData] = useState([]);
  const [maleRegisteredPercent, setMaleRegisteredPercent] = useState(0);
  const [femaleRegisteredPercent, setFemaleRegisteredPercent] = useState(0);
  const [maleStandBy, setMaleStandBy] = useState();
  const [maleIntake, setMaleIntake] = useState();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);

  const [femaleStandBy, setFemaleStandBy] = useState();
  const [femaleIntake, setFemaleIntake] = useState();

  const getApplicationOpening = async () => {
    setLoading(true);
    await axios
      .get(`${BACKEND_URL}/openings`)
      .then(function (response) {
        setMaleIntake(response.data.maleLimit);
        setMaleStandBy(response.data.maleReservation);
        setFemaleIntake(response.data.femaleLimit);
        setFemaleStandBy(response.data.femaleReservation);
        setLoading(false);
        console.log("openingData", response.data);
        setOpeningData(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoading(false);
        setServerError(true);
      });
  };

  useEffect(() => {
    getApplicationOpening();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(maleQuery, (snapshot) => {
      let maleRegistrations = [];
      let femaleRegistrations = [];
      snapshot.forEach((doc) => {
        const item = doc.data();
        if (item.gender === "Female") femaleRegistrations.push(item);
        else maleRegistrations.push(item);
      });
      if (femaleRegistrations.length <= maleIntake) {
        setFemaleRegisteredPercent((femaleRegistrations.length / maleIntake) * 100);
      }
      if (maleRegistrations.length <= femaleIntake) {
        setMaleRegisteredPercent((maleRegistrations.length / femaleIntake) * 100);
      }
      if (femaleRegistrations.length) {
        setFemaleRegistrationData(femaleRegistrations);
      }
      if (maleRegistrations.length) {
        setMaleRegistrationData(maleRegistrations);
      }
    });
    return () => unsubscribe;
  }, [femaleIntake, maleIntake]);

  return (
    <>
      <div style={{ animation: `fadeIn 1s` }}>
        {serverError && (
          <>
            <div
              style={{
                // backgroundColor: "#ffd54f",
                paddingTop: "100px",
              }}
            >
              {/* <Loader type="Bars" color="#00BFFF" height={80} width={80} /> */}
              <Loader
                type="ThreeDots"
                color="#ffd54f"
                height={80}
                width={80}
                // timeout={3000} //3 secs
              />
              <br />
              <h4 id="headline">Loading</h4>
              <br />
              <p>This is taking longer than usual</p>
              <br />
              <Button variant="warning" size="lg" onClick={() => window.location.reload()}>
                Reload
              </Button>
            </div>
          </>
        )}
      </div>
      {!loading && openingData && (
        <>
          <Container>
            <Row className="pt-1 pb-4">
              <Col>
                <h3>Live Registrations</h3>
                This page shows the registration data in realtime!
                {openingData && (
                  <>
                    <Alert variant="primary" className="mt-1">
                      {openingData.announcementText}
                      <br />
                    </Alert>
                    <strong>NOTE: </strong> If you are on mobile, we recommend you to close this page before applying. It might slow your device due to continuous data push from our server. This data will also be available after the registration is closed
                    <br />
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="pt-1 pb-4">
              <Col xs={12} md={6} className="mb-4">
                <h3>Male Registrations</h3>
                <ProgressBar variant="info" animated now={maleRegisteredPercent} />
                <Alert variant="info " className="mt-1">
                  {maleRegistrationData.length <= maleIntake ? maleRegistrationData.length : maleIntake} out of {maleIntake} registered
                </Alert>
                <br />

                <div
                  style={{
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column-reverse",
                    paddingBottom: "20px",
                  }}
                  className="overflow-auto"
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>CID</th>
                        <th>Name</th>
                        <th>Applied At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {maleRegistrationData &&
                        maleRegistrationData.map((item, key) => {
                          if (key >= maleStandBy)
                            return (
                              <tr key={key} className="bg-danger">
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          else if (key >= maleIntake)
                            return (
                              <tr key={key} className="bg-warning">
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          else
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                        })}
                    </tbody>
                  </Table>
                  <Table>
                    {maleRegistrationData.length <= 0 && (
                      <tbody>
                        <tr>
                          <td colSpan="4">Registration has not started. Male registrations will appear here as and when users apply!</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
                <Alert variant="warning">Rows highlighted in yellow are waiting list</Alert>
              </Col>
              <Col xs={12} md={6}>
                <h3>Female Registrations</h3>
                <ProgressBar animated variant="success" now={femaleRegisteredPercent} />
                {/* <Alert variant="success" className="mt-1">
              {femaleRegistrationData.length} out of 840 (including waiting
              list) registered
            </Alert> */}
                <Alert variant="success" className="mt-1">
                  {femaleRegistrationData.length <= femaleIntake ? femaleRegistrationData.length : femaleIntake} out of {femaleIntake} registered
                </Alert>
                <br />
                <Table>
                  {femaleRegistrationData.length <= 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="4">Registration has not started. Female registrations will appear here as and when users apply!</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column-reverse",
                    paddingBottom: "20px",
                  }}
                  className="overflow-auto"
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>CID</th>
                        <th>Name</th>
                        <th>Applied At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {femaleRegistrationData &&
                        femaleRegistrationData.map((item, key) => {
                          if (key >= femaleStandBy)
                            return (
                              <tr key={key} className="bg-danger">
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          if (key >= femaleIntake)
                            return (
                              <tr key={key} className="bg-warning">
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          else
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.cid}</td>
                                <td>{item.name}</td>
                                <td>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(item.created_at.toDate()).toString()}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                        })}
                    </tbody>
                  </Table>
                </div>
                <Alert variant="warning">Rows highlighted in yellow are waiting list</Alert>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default LiveRegistrations;
