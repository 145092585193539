import React, { useEffect, useState } from "react"
import Clock from "react-clock"
import { Row, Col } from "react-bootstrap"

const UserClock = () => {
  const [value, setValue] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Row
      className="user-time"
      style={{ textAlign: "center", position: "relative" }}
    >
      <Col>
        <div>
          <div>Your System Time</div>
          <div
            style={{
              fontWeight: "400",
              fontSize: 10,
            }}
          >
            {value.toString()}
          </div>
          <div
            style={{
              position: "absolute",
              right: 20,
            }}
          >
            <Clock
              value={value}
              renderNumbers={true}
              size={70}
              className="bg-red clock-radius"
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default UserClock
