import desuup from "./desuup.png"
import logo from "./logo.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { Routes, Route } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import "react-datepicker/dist/react-datepicker.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import RegistrationCount from "./components/RegistrationCount"
import LiveRegistrations from "./containers/LiveRegistrations"
import Home from "./containers/Home"

import "react-clock/dist/Clock.css"

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundColor: "white",
        paddingBottom: "50px",
      }}
    >
      <Container>
        <Row className="pt-5 pb-4">
          <Col xs={12} md={3}>
            <a href="/">
              <img src={logo} className="logo" alt="logo" />
            </a>
          </Col>
          <Col md={6}>
            <header>
              <h2 style={{ fontWeight: "bold" }}>
                {" "}
                Online De-suung Registration System
              </h2>
              <div>
                <p style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  "The ultimate objective of nation building is peace and
                  harmony for Bhutan and her people."
                </p>
                <span> - HIS MAJESTY THE DRUK GYALPO</span>
              </div>
            </header>
          </Col>
          <Col xs={12} md={3}>
            <img src={desuup} className="App-logo" alt="logo" />
          </Col>
        </Row>
      </Container>
      <RegistrationCount />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="live-registration-data" element={<LiveRegistrations />} />
      </Routes>
    </div>
  )
}

export default App
