export const PickupPoints = [
  {
    dzongkhag_name: "Thimphu",
    pickupPoints: [
      {
        name: "Changlingmithang Parking",
      },
    ],
  },
  {
    dzongkhag_name: "Paro",
    pickupPoints: [
      {
        name: "Paro De-suung Office",
      },
    ],
  },
  {
    dzongkhag_name: "Bumthang",
    pickupPoints: [
      {
        name: "Chamkhar Bus Stop",
      },
    ],
  },
  {
    dzongkhag_name: "Chhukha",
    pickupPoints: [
      {
        name: "Gedu College",
      },
      {
        name: "Nebesa Junction",
      },
      {
        name: "Phuntsholing LSS",
      },
    ],
  },
  {
    dzongkhag_name: "Dagana",
    pickupPoints: [
      {
        name: "Dagapela Zero Point",
      },
    ],
  },
  {
    dzongkhag_name: "Gasa",
    pickupPoints: [
      {
        name: "Gasa Dzong",
      },
    ],
  },
  {
    dzongkhag_name: "Haa",
    pickupPoints: [
      {
        name: "Haa De-suung Office",
      },
    ],
  },
  {
    dzongkhag_name: "Lhuentse",
    pickupPoints: [
      {
        name: "Dzong Parking",
      },
    ],
  },
  {
    dzongkhag_name: "Monggar",
    pickupPoints: [
      {
        name: "Monggar LSS",
      },
    ],
  },
  {
    dzongkhag_name: "Pema Gatshel",
    pickupPoints: [
      {
        name: "Dzong Parking",
      },
      {
        name: "Nganglam Drungkhag Office",
      },
    ],
  },
  {
    dzongkhag_name: "Punakha",
    pickupPoints: [
      {
        name: "Damchen Parking (Khuruthang)",
      },
    ],
  },
  {
    dzongkhag_name: "Samdrup Jongkhar",
    pickupPoints: [
      {
        name: "RSTA Office",
      },
    ],
  },
  {
    dzongkhag_name: "Samtse",
    pickupPoints: [
      {
        name: "Truck Parking(Above FCB)",
      },
    ],
  },
  {
    dzongkhag_name: "Sarpang",
    pickupPoints: [
      {
        name: "Covid Incident Command Office(Near Old Drungkhag)",
      },
    ],
  },
  {
    dzongkhag_name: "Trashigang",
    pickupPoints: [
      {
        name: "Royal Guest House Parking",
      },
    ],
  },
  {
    dzongkhag_name: "Trashi Yangtse",
    pickupPoints: [
      {
        name: "Doksum",
      },

      {
        name: "Trashi Yangtse Town",
      },
    ],
  },
  {
    dzongkhag_name: "Trongsa",
    pickupPoints: [
      {
        name: "Thruepang Parking",
      },
    ],
  },
  {
    dzongkhag_name: "Tsirang",
    pickupPoints: [
      {
        name: "Town Bus Station",
      },
    ],
  },
  {
    dzongkhag_name: "Wangdue Phodrang",
    pickupPoints: [
      {
        name: "RSTA Parking",
      },
    ],
  },
  {
    dzongkhag_name: "Zhemgang",
    pickupPoints: [
      {
        name: "Panbang Drungkhag",
      },
      {
        name: "Zhemgang Dzongkhag",
      },
    ],
  },
];
